import { Modal, Sheet, ModalClose, Typography, Button } from "@mui/joy";

function ConfirmEmailModal({closePopupExternal, sendEmailExternal, open}) {

    const closePopup = () => {
        closePopupExternal();
    }

    const sendEmail = () => {
        sendEmailExternal();
        closePopupExternal();
    }

    return (<>
    <Modal aria-labelledby="modal-title"
    aria-describedby="modal-desc" open={open} onClose={closePopup} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column"
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Confirm Send Visit
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary" paddingBottom='10px'>
                By using this function, I confirm that I have obtained patient consent to send personal health information by email. The note details will be shared outside the clinicassist platform.
            </Typography>
            <Button onClick={sendEmail}>CONFIRM</Button>
        </Sheet>
    </Modal>
    </>)
}

export default ConfirmEmailModal;