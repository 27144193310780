import React from 'react';
import { Modal, Box, Typography, IconButton, Button, FormControl, FormHelperText, Sheet } from '@mui/joy';
import CancelPresentation from '@mui/icons-material/CancelPresentation';
import FloatingLabelInput from "./joy/FloatingInput";

function TOSModal({
    showPopup,
    setShowPopup, 
    setAgreeValue,
    emailValue, 
    showSignatureButton = false, 
    confirmName, 
    handleConfirmNameChange, 
    handleSignature, 
    signatureError,
    signatureDate,
    name, 
}) {

return (
    <Modal
        open={showPopup}
        onClose={() => setShowPopup(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
        
        <Sheet
            variant="plain"
            id='popup-sheet'
            sx={{
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
            }}
        >
            <IconButton 
                onClick={() => {
                    setShowPopup(false);
                    setAgreeValue && setAgreeValue(false);
                }} 
                sx={{width:"2rem"}}
            >
                <CancelPresentation fontSize="large" sx={{alignSelf: "center"}}/>
            </IconButton>
            {!showSignatureButton && (
                <Typography sx={{fontWeight: 'bold'}}>You signed the terms of service agreement on: {signatureDate}</Typography>
            )}
            <Typography sx={{fontWeight: 'bold'}}>medX Insight Inc. - clinicassist.ai Terms of Service Agreement</Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                1. Non-Exclusive Licence Agreement
            </Typography>
            <Typography
                level="body-sm"
            >
                This licence agreement (the "Agreement") between medX Insight ("Licensor") and you or the entity 
                you represent ("You") sets out the terms and conditions governing your access and/or use of the 
                software (“Software”) as defined para. 2 of this Agreement. By typing <b> {name} </b>   
                at the end of this Agreement, you indicate your acceptance of the terms of this Agreement. 
                This Agreement takes effect on the date you accept the terms of the Agreement in accordance with 
                this section (the "Effective Date"). You represent that You have reached the age of majority and 
                are therefore legally able to enter into contracts.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                2. Description and Usage of the Software
            </Typography>
            <Typography
                level="body-sm"
            >
                The Software, also known as clinicassist.ai, is a voice-to-text transcription tool used to assist in
                the note-taking for patient-physician meetings. Using your device’s microphone, clinicassist.ai will 
                listen to and transcribe speech heard in the interaction. When ready, the physician can then automatically 
                generate a SOAP note, based on all the text transcribed. The SOAP note is generated using an AI model, 
                placing an emphasis on symptoms and pains while filtering out filler words. Before using the tool, the physician 
                should receive consent and permissions required. Once the patient has provided consent, the physician should 
                press the start button.
                The Software, also known as clinicassist.ai, is a voice-to-text transcription tool used to assist in the note-taking 
                for patient-physician meetings. Using your device’s microphone, clinicassist.ai will listen to and transcribe speech 
                heard in the interaction. When ready, the physician can then automatically generate a SOAP note, based on all the 
                text transcribed. The SOAP note is generated using an AI model, placing an emphasis on symptoms and pains while 
                filtering out filler words. Before using the tool, the physician should receive consent and permissions required. 
                Once the patient has provided consent, the physician should press the start button.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                3. Grant of Licence
            </Typography>
            <Typography
                level="body-sm"
            >
                Licensor grants You a limited, revocable, non-exclusive, non-sublicensable, non-transferable licence to access and/or use the 
                Software to transcribe conversations. You agree that You may not use the Software in any manner or for any purpose other than 
                as expressly permitted by this Agreement and in particular You may not:
            </Typography>
            <Typography level="body-sm">
                &emsp;(a) modify, alter, tamper with or create a derivative work of the Software;
            </Typography>
            <Typography level="body-sm">
                &emsp;(b) reverse engineer, disassemble or decompile the Software; or
            </Typography>
            <Typography level="body-sm">
                &emsp;(c) resell or sublicense the Software.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                4. Modification of Licence
            </Typography>
            <Typography
                level="body-sm"
            >
                Licensor may modify the terms of this Agreement at any time without notice. 
                Any additional or modified terms will be effective upon being posted on the 
                Licensor's website and/or Software. It is your responsibility to review the 
                Agreement periodically on the website and/or Software. Your continued use of the 
                Software constitutes your acceptance of the modified or additional 
                terms.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                5. Proprietary and Intellectual Property Rights
            </Typography>
            <Typography
                level="body-sm"
            >
                As between Licensor and You, Licensor owns all right, title and interest in and to the Software. You 
                acknowledge and agree that the Software is licensed to You only for the term of this Agreement and 
                in accordance with the terms and conditions of this Agreement. Except for the limited rights granted 
                in this Agreement, all rights are reserved by Licensor.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                6. Term
            </Typography>
            <Typography
                level="body-sm"
            >
                The term of this Agreement commences on the Effective Date and remains in effect until terminated in accordance with para. 7 of this Agreement.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                7. Termination
            </Typography>
            <Typography
                level="body-sm"
            >
                This Agreement may be terminated by either party upon 30 days' prior written or electronic notice to the other party.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                8. Fees and Payment
            </Typography>
            <Typography
                level="body-sm"
            >
                You hereby recognize that the Licensor accepts fees or payment for use of the Software at this time through Stripe.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                9. Disclaimer
            </Typography>
            <Typography
                level="body-sm"
            >
                The Software is provided "as is", without warranty of any kind, either express or implied, including but not limited 
                to the implied warranties of merchantability or fitness for a particular purpose. Licensor gives no warranty that the 
                Software will meet your particular requirements or that the operation of the Software will be uninterrupted or error free. 
                You agree that the Licensor has taken appropriate measures to inform You of any risks associated with using the Software 
                throughout the entirety of this Agreement. The Software is provided for general informational purposed only and is not a 
                substitute for professional advice. Accordingly, before taking any actions based upon such information, You agree to consult 
                with the appropriate professionals. The Software does not provide any kind of actionable or significant medical/health advice.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                10. Limitation of Liability
            </Typography>
            <Typography
                level="body-sm"
            >
                In no event shall Licensor, its directors, officers, agents, employees or affiliates be liable to You for any direct, indirect, 
                special, consequential or exemplary damages arising out of or related to the use or inability to use the Software, even if 
                Licensor has been advised of the possibility of such damages. This limitation of liability shall apply to any claim or cause 
                of action whatsoever whether such claim or cause of action is based on contract, tort, strict liability or other theory.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                11. Responsibility for Use of Software
            </Typography>
            <Typography
                level="body-sm"
            >
                You are responsible and liable for all uses of the Software through access thereto provided by You, directly or indirectly. 
                Specifically, and without limiting the generality of the foregoing, You are responsible and liable for all actions and 
                failures to take required actions with respect to the Software by You or by any other person to whom You or any other person 
                may provide access to or use of the Software, whether such access or use is permitted by or in violation of this Agreement. 
                Use of the Software is intended for registered professional doctors and/or physicians who are experienced and responsible in 
                the diagnosis, analysis, testing, and treatment of any medical and all medical complications. You are also assumed to be a 
                current, valid member of the College of Physicians and Surgeons of Ontario OR a licensed medical doctor and/or physician in 
                Ontario, Canada, OR a licensed healthcare practitioner in the United States. In addition, You shall have enough judgment in 
                input and output such that You can verify all software output. This program shall not be used by any individual who can’t hand 
                verify the program output or who does not have prior experience in responsible charge (that is being the physician and/or doctor of record). 
                Every attempt has been made to provide a sound Software that outputs appropriate results. The Software Licensor is not liable 
                implicitly or explicitly for any and all damages arising from the use of the Software. The accuracy and use of this program rests 
                entirely on You only. All responsibility for final diagnosis, treatment, testing, and/or analysis resides with You. The 
                Software should not be used for analysis unless appropriate and reasonable caution is exercised and both input and output is 
                verified by an experienced and registered professional doctor and/or physician. You are responsible for obtaining any and all 
                permissions and/or consent(s) required before using the Software. You agree that You are solely responsible for any and all harm 
                caused by use of the Software.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                12. Indemnity
            </Typography>
            <Typography
                level="body-sm"
            >
                You agree to indemnify and hold harmless Licensor together with its directors, officers, agents, employees and affiliates, 
                from and against all liability, causes of action, claims, costs and expenses arising out of or resulting from your use 
                and/or access of the Software. You hereby agree that the Licensor has informed You that the Software is not a substitute for 
                medical advice and/or counsel. In any given situation, medical diagnoses, treatments, and tests depend on many factors.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                13. Privacy
            </Typography>
            <Typography
                level="body-sm"
            >
                Licensor is committed to respecting your privacy rights. The only information retained by the Licensor is limited to the 
                information entered by You in the process of providing access to the Software to You. These are the fields entered by 
                You on the Software. All other information will not be retained, protected, and or managed by the Licensor. You agree to 
                indemnify and hold harmless Licensor together with its directors, officers, agents, employees and affiliates, from and 
                against all liability, causes of action, claims, costs and expenses arising out of or resulting from the information you 
                provide through the use and or access of the Software.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                14. Use of Third Party Software
            </Typography>
            <Typography
                level="body-sm"
            >
                By using the Software, You agree to comply with the privacy policies of any and all Third Party Softwares (as defined in para. 13). 
                Third Party Softwares are defined as any and all third party service providers that medX Insight is working with. You agree that 
                OpenAI may retain Your data for 30 days for abuse and misuse monitoring purposes. A limited number of authorized OpenAI employees, 
                as well as specialized third-party contractors that are subject to confidentiality and security obligations, can access this data 
                solely to investigate and verify suspected abuse. OpenAI may still have content classifiers flag when data is suspected to contain 
                platform abuse. You hereby agree that Google's servers are used to perform the speech to text transcription. Using the feature sends 
                an audio recording to Google (audio data is not sent directly to the page itself), along with the domain of the website using the API, 
                your default browser language and the language settings of the website. Cookies are not sent along with these requests.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                15. Notices
            </Typography>
            <Typography
                level="body-sm"
            >
                All notices given under this Agreement shall be in writing or electronic mail. The Licensor hereby agrees to receive notice via email at 
                team@medxinsight.com. You hereby agree to receive notice via email at <b> {emailValue}</b>.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                16. No Waiver
            </Typography>
            <Typography
                level="body-sm"
            >
                No provision of this Agreement shall be deemed to be waived by Licensor unless such waiver is in writing and signed by the Licensor. No 
                failure by Licensor to exercise and no delay by Licensor in exercising any right or remedy under this Agreement will be deemed to be a 
                waiver by Licensor of that right or remedy. No waiver by Licensor of any breach by You of this Agreement shall be deemed to be a waiver 
                of any subsequent breach by You of that provision.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                17. Severability
            </Typography>
            <Typography
                level="body-sm"
            >
                If any court of competent jurisdiction holds that any provision in this Agreement is invalid, illegal or unenforceable, the 
                validity, legality and enforceability of the remaining provisions of this Agreement will remain in full force and effect. Any 
                invalid or unenforceable portions will be interpreted to effect and intent of the original portion. If such construction is not possible, 
                the invalid or unenforceable portion will be severed from this Agreement but the rest of the Agreement will remain in full force and effect.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                18. Governing Law
            </Typography>
            <Typography
                level="body-sm"
            >
                This Agreement shall be governed by and construed in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                19. Entire Agreement
            </Typography>
            <Typography
                level="body-sm"
            >
                This Agreement forms the entire agreement between the parties with respect to the subject matter hereof and supersedes and cancels any and all 
                prior or contemporaneous discussions, negotiations, arrangements and agreements between them, express or implied, oral or written, with respect 
                to such subject matter hereof.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                20. Assignment
            </Typography>
            <Typography
                level="body-sm"
            >
                You will not assign this Agreement or sublicense any of your rights under this Agreement without the prior written consent of Licensor. 
                Subject to the foregoing, this Agreement will be binding upon, and inure to the benefit of, the parties and their respective successors and assigns.
            </Typography>
            <Typography
                sx={{fontWeight: 'bold'}}
            >
                21. Acknowledgment
            </Typography>
            <Typography
                level="body-sm"
            >
                You acknowledge that You have read this licence, understand it and agree to be bound by its terms
                and conditions.
            </Typography>
            {showSignatureButton && (<Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.75rem',
                }}
            >
                <Typography sx={{fontWeight: 'bold'}}>
                    Please enter your full name as a signature:  
                </Typography>
                <FormControl >
                    <FloatingLabelInput 
                        label="First and Last Name"
                        value={confirmName}
                        onChange={handleConfirmNameChange}
                    />
                    {signatureError && <FormHelperText sx={{color: 'red'}}>
                        {signatureError} 
                    </FormHelperText>}
                </FormControl>
                <Button onClick={handleSignature} sx={{ fontSize: "1rem", padding: '10px'}}>I Accept</Button>
            </Box>)}
            {!showSignatureButton && (
                <Typography level="body-sm">Signed by, {name}</Typography>
            )}
        </Sheet>
    </Modal>
    );
}

export default TOSModal;