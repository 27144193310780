import React, { Component, createRef } from "react";
import "../../styling/CurrentVisit.css";
import "../../styling/ComponentStyling.css"; // Ensure this is imported
import TimerComponent from "../../components/Timer.js";
import AudioAnalyser from '../../components/AudioAnalyzer.js';
import { Accordion, AccordionDetails, AccordionSummary, Typography, IconButton, LinearProgress, Box, Button, Tooltip, Sheet, Select, Option, Radio, RadioGroup, Stepper, Step, StepIndicator, Input, Divider } from "@mui/joy";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { copyToClipboard } from "../../helpers/CopyToCliboard.js";
import { authGuard, getUserID } from "../../helpers/AuthBarrier";
import { downloadText, downloadTextoTxt } from "../../helpers/DownloadAndFormat.js";
import { AlertContext } from "../../components/AlertFlag.js";
import { getPresignedURL, putAudioIntoPresigned } from "../../helpers/AWSHelpers.js";
import { axiosInstance } from "../../helpers/axiosConfig.js";
import NavBlocker from "../../components/NavBlocker.js";
import { nonJsonToJson, formattable, checkExistingTitles } from "../../helpers/TextFormat.js";
import { calculateAndConvertTime, calculateTimeDifference } from "../../helpers/TimeConversionHelper.js";
import NameAppointmentPopup from "../../components/NameAppointmentPopup.js";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmEmailModal from "../../components/ConfirmSendEmail.js";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import IosShareIcon from '@mui/icons-material/IosShare';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

class NewVisitView extends Component {
    static contextType = AlertContext;
    constructor(props) {
        super(props)
        this.state = {
            currentDate: new Date(),
            visitTime: "Not started",
            noteType: "",
            noteName: "New Visit",
            downloadFormat: 'pdf',
            loadingProgress: 0,
            noteGenerated: null,
            capturing: false,
            canGenerate: false,
            loadingNote: false,
            audio: null,
            noteEditable: false,
            transcriptEditable: false,
            transcriptGenerated: null,
            promptNameAppointment: false,
            timeAllowed: false,
            triggerSendEmail: false,
            isDivVisible: false,
            windowWidth: window.innerWidth,
        }
        this.temporaryScreenCheck = null;
        this.limitReached = false;
        this.userPreferences = {};
        this.visitID = null;
        this.noteRef = createRef();
        this.specialRefs = [];
        this.titles = [];
        this.parts = [];
        this.transcriptRef = createRef();
        this.transcriptParts = [];
        this.transcriptSpecialRefs = [createRef(), createRef()];
        this.audioChunks = [];
        this.mediaRecorder = null;
        this.toggleMicrophone = this.toggleMicrophone.bind(this);
        this.generateProcess = this.generateProcess.bind(this);
        this.autoStarted = false;
        this.audioCodecs = '';
        this.wakeLock = null;
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    }

    toggleDivVisibility = () => {
        this.setState(prevState => ({
          isDivVisible: !prevState.isDivVisible
        }));
      };

    handleEditTranscript = () => {
        this.setState({
            transcriptEditable: true,
        })
        setTimeout(() => this.transcriptRef.current.focus(), 0); // Queue focus ref at end of react render queue
    }

    handleCancelSaveTranscript = () => {
        this.setState((prevState) => ({
            transcriptEditable: false,
            transcriptGenerated: prevState.transcriptGenerated,
        }));
        if (this.transcriptRef.current) {
            this.transcriptRef.current.textContent = this.state.transcriptGenerated;
        }
        for (let index = 0; index < this.transcriptSpecialRefs.length; index++) {
            if (this.transcriptSpecialRefs[index].current) {
                this.transcriptSpecialRefs[index].current.textContent = this.transcriptParts[index];
            }
        }
    }

    createNoteText = () => {
        let noteText = '';
        if (checkExistingTitles(this.state.noteGenerated)) {
            this.titles.forEach( (title, index) => {
                noteText += index === 0 ? `**${title}**\n` : `\n\n**${title}**\n `;
                noteText += this.specialRefs[index].current.innerText;
            })
        } else {
            noteText = this.noteRef.current.textContent.trim();
        }

        return noteText;
    }

    setRefs = (index) => {
        this.specialRefs[index] = createRef();
    }

    setTitles= (title) => {
        this.titles = title;
    }

    handleRegenerateNote = async () => {
        this.transcriptRef.current.blur();
        const tempNote = this.state.noteGenerated;
        const tempTranscript = this.transcriptRef.current.innerText;
        this.setState({
            transcriptEditable: false,
            loadingProgress: 0,
            loadingNote: true,
            noteGenerated: null,
            transcriptGenerated: null,
        });
        // Save edited note
        await axiosInstance.put(`/api/v1/visit/${this.visitID}`, {
            "title": this.state.noteName,
            "user_edited_transcript": {
                "value": tempTranscript,
            }
        }).catch(error => {
            this.context.addAlert('There was a problem regenerating your note', 'danger');
            this.setState({
                loadingNote: false,
                noteGenerated: tempNote,
                transcriptGenerated: tempTranscript,
            })
            return;
        })

        await axiosInstance.post(`/api/v1/visit/${this.visitID}/regenerate_note`, {
            "transcript_source": 'user_edit',
        }).then(response => {
            this.context.addAlert('Regeneration has been queued', 'success');

            const updateProgressIntervalID = setInterval(() => {
                if (this.state.loadingProgress < 99) {
                    this.updateProgress();
                }
            }, 500);

            const intervalID = setInterval( async () => {
                try {
                    const response = await axiosInstance.get(`/api/v1/visit/${this.visitID}`);
    
                    if (response.data['generate_note'] !== null && response.data['transcription'] !== null) {
                        this.setState({
                            loadingNote: false,
                            noteGenerated: response.data['generate_note']['generated_result']['value'],
                            transcriptGenerated: tempTranscript,
                            loadingProgress: 100,
                        })
                        clearInterval(intervalID);
                        clearInterval(updateProgressIntervalID);
                    }
                }
                catch(error) {
                    this.context.addAlert('Something unexpected happen. Please try again', 'danger');
                    this.setState({
                        loadingNote: false,
                        noteGenerated: tempNote,
                        transcriptGenerated: tempTranscript,
                    })
                    clearInterval(intervalID);
                    clearInterval(updateProgressIntervalID);
                }
            }, 4000)


        }).catch(error => {
            this.context.addAlert('There was a problem regenerating your note', 'danger');
            this.setState({
                loadingNote: false,
                noteGenerated: tempNote,
                transcriptGenerated: tempTranscript,
            })
        })

    }

    handleNewSaveNote = async () => {
        this.setState({
            noteEditable: false,
        })

        const request_body = {
            "user_edited_result": {
                "value": this.createNoteText(),
            },
            "user_edited_transcript": {
                "value": this.transcriptRef.current.innerText,
            },
            "title": this.state.noteName
        }

        await axiosInstance.put(`/api/v1/visit/${this.visitID}`, request_body).catch( error => {
            this.context.addAlert('There was an issue saving your note.', 'danger');
        }).then ( response => {
            this.context.addAlert('Saved!', 'success');
            this.setState({
                noteGenerated: response.data['user_edited_result']['value'],
            })
        })
    }

    handleCancelSaveNote = () => {
        this.setState((prevState) => ({
            noteGenerated: prevState.noteGenerated,
            noteEditable: false,
        }))

        if (this.noteRef.current) {
            this.noteRef.current.textContent = this.state.noteGenerated;
        }
        for (let index = 0; index < this.specialRefs.length ; index++) {
            if (this.specialRefs[index].current) {
                this.specialRefs[index].current.textContent = this.parts[index]
            }
        }
    }

    setFormattableParts = (parts) => {
        this.parts = parts;
    }

    handleEditNote = () => {
        this.setState({
            noteEditable: true,
        })
        setTimeout(() => formattable.includes(this.state.noteType) ? null : this.noteRef.current.focus(), 0);
    }

    handleBeforeUnload = (e) => {
        if (this.queuing) {
            const message = "Your visit is still queuing! Are you sure you want to leave?";
            e.returnValue = message;
            return message;
        } else if (this.state.capturing) {
            const message = "Your meeting is still in progress. Are you sure you want to leave?";
            e.returnValue = message;
            return message;
        }
    }

    getUserPreferences = async () => {
        await axiosInstance.get(`/api/v1/user/${getUserID()}/preference`).then( retrieved => {
            this.setState({
                noteType: retrieved.data['note_type'],
            })
            this.userPreferences = retrieved.data;
        }).catch(error => {
            this.context.addAlert('There was a problem retrieving your note type', 'danger');
        })
        await axiosInstance.get(`/api/v1/visit`, {
            params: {
                'from_time': calculateAndConvertTime('3 months'),
                'to_time': calculateAndConvertTime('current'),
                'sort': '-created_at',
                'size': 1,
            }
        }).then( retrieved => {
            this.setState({
                noteName: `New Visit ${retrieved.data['total'] + 1}`,
            })
        }).catch( error => {
            this.context.addAlert('There was a problem retrieving your note type', 'danger');
        })
    }

    getVisitCapacity = async () => {
        return axiosInstance.get(`/api/v1/visit/capacity`).then( retrieved => {
            return retrieved.data;
        }).catch(error => {
            this.context.addAlert('There was a problem retrieving your visit capacity', 'danger');
            return {can_create: false};
        })
    }

    componentDidMount() {
        this.getUserPreferences();
        if (this.props.autoStart && !this.autoStarted) {
            this.autoStarted = true;
            setTimeout(() => this.toggleMicrophone(), 0);
        }
        window.addEventListener('resize', this.handleResize);
        window.addEventListener("beforeunload", this.handleBeforeUnload);

        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        if (this.state.audio) {
            this.stopMicrophone();
        }
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleBeforeUnload(event) {
        const { capturing, loadingProgress, loadingNote, canGenerate, transcriptEditable, noteEditable } = this.state;
        const isDirty = capturing || (loadingProgress < 10 && loadingNote) || canGenerate || transcriptEditable || noteEditable || this.audioChunks.length > 0;

        if (isDirty) {
            event.preventDefault();
            event.returnValue = ''; // This triggers the browser dialog
            return ''; // Some browsers require this for the dialog to be shown
        }

       
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleNoteType = (event, noteType) => {
        this.setState({
            noteType: noteType
        })
    }

    async getMicrophone() {
        let audio;
        try {
            audio = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            });
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                this.context.addAlert('Please give clinicassist permission to access your microphone.', 'danger');
            }
            return;
        }
 
        if ('mimeTypes' in navigator) {
            if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus')) {
                this.mediaRecorder = new MediaRecorder(audio, { mimeType: 'audio/webm; codecs=opus' });
                this.audioCodecs = 'audio/webm; codecs=opus'
            } else if (MediaRecorder.isTypeSupported('audio/mp4; codecs=mp4a.40.2')) {
                 this.mediaRecorder = new MediaRecorder(audio, { mimeType: 'audio/mp4; codecs=mp4a.40.2' });
                 this.audioCodecs = 'audio/mp4; codecs=mp4a.40.2'
            } else if (MediaRecorder.isTypeSupported('audio/webm')) {
                this.mediaRecorder = new MediaRecorder(audio, { mimeType: 'audio/webm' });
                this.audioCodecs = 'audio/webm'
            } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                this.mediaRecorder = new MediaRecorder(audio, { mimeType: 'audio/mp4' });
                this.audioCodecs = 'audio/mp4'
            } else {
                this.mediaRecorder = new MediaRecorder(audio);
            }
        } else {
            this.mediaRecorder = new MediaRecorder(audio);
        }

        try {
            this.wakeLock = await navigator.wakeLock.request("screen");
            console.log('Wake log requested');
        } catch (error) {
            console.log(error);
        }

        this.mediaRecorder.ondataavailable = (event) => {
            if (!this.state.canGenerate) {
                this.setState({
                    canGenerate: true,
                })
            }
            this.audioChunks.push(event.data);
        }

        this.mediaRecorder.start();
        this.setState({ audio: audio, capturing: true });
    }

    async toggleMicrophone() {
        if (!this.limitReached) {
            const visitCapacity = await this.getVisitCapacity();
            if (this.state.audio) {
                await this.stopMicrophone();
            } else if (!visitCapacity.can_create) {
                let detailedMessage = "You’ve reached the maximum number of daily visits for your subscription. Upgrade for more daily visits";
                if (visitCapacity.next_visit_create_time) {
                    const timeToNextVisit = calculateTimeDifference(visitCapacity.next_visit_create_time);
                    const hours = timeToNextVisit.hours;
                    const minutes = timeToNextVisit.minutes
                    detailedMessage += ` or try again in ${hours > 0 ? `${hours} hour${hours > 1? "s":""}` : `${minutes > 1 ? `${minutes} minutes` : "1 minute"}`}.`;
                }
                this.context.addAlert(detailedMessage, 'danger');
            } else {
                if (this.state.visitTime === 'Not started') {
                    // Double check they have a valid token
                    authGuard();
                    const nowTime = new Date();
                    this.setState({
                        visitTime: `${nowTime.toLocaleTimeString()}`,
                    })
                }
                await this.getMicrophone();
            }
        } else {
            this.context.addAlert("You've hit the recording limit of 2 hours. Please hit 'complete' to save the note for your visit.", 'danger');
        }
    }

    async stopMicrophone() {
        this.mediaRecorder.stop();
        this.state.audio.getTracks().forEach(track => track.stop());
        this.mediaRecorder.stream.getTracks().forEach(track => track.stop());

        if (this.wakeLock) {
            this.wakeLock.release().then(() => {
                this.wakeLock = null;
                console.log("Wake lock released");
            })
        }
        this.mediaRecorder = null;
        this.setState({ 
            audio: null,
            capturing: false,
        });
    }

    handleNoteDownloadFormat = (event) => {
        this.setState({
            downloadFormat: event.target.value,
        })
    }

    allowGeneration = () => {
        this.setState({
            timeAllowed: true,
        })
    }

    updateProgress = () => {
        const currentProgress = this.state.loadingProgress;
        if (currentProgress >= 99) {
            return; 
        }
        let increment;

        if (currentProgress < 80) {
            increment = Math.random() * 3 + 1;
        } else {
            increment = Math.random() * 1 + 0.1;
        }
        
        const newProgress = Math.min(currentProgress + increment, 99);
        this.setState({ loadingProgress: newProgress });
    }

    async generateProcess() {
        if (this.state.capturing) {
            this.context.addAlert("Please pause the recording first", 'danger');
        } else if (!this.state.timeAllowed) {
            this.context.addAlert( "Record at least 20 seconds of audio before generating a note", 'danger');
        } else {
            try {
                // Generation workflow as described in docs
                const audioBlob = this.audioCodecs.length > 0 ? new Blob(this.audioChunks, { type: this.audioCodecs }) : new Blob(this.audioChunks);
                if (audioBlob.size < 10 || !audioBlob.size){
                    this.context.addAlert("Something went wrong creating your audio file. Please try again later.", 'danger');
                    return;
                }
                this.setState({
                    loadingNote: true,
                    timeAllowed: false,
                })
                const presignedFields = await getPresignedURL();
                await putAudioIntoPresigned(presignedFields, audioBlob);
                this.audioChunks = [];
                
                await axiosInstance.post('/api/v1/visit', {
                    'file_location': presignedFields['fields']['key'],
                    'title': this.state.noteName,
                    'split_hpi': this.userPreferences['split_hpi'],
                    'note_format': this.userPreferences['note_format'],
                    'note_type': this.state.noteType,
                    'limit_length': this.userPreferences['limit_length'],
                }).then( response => {
                    this.visitID = response.data['id']
                    this.context.addAlert("Your note has been queued! You can safely navigate away now.", "success");
                    this.setState({
                        canGenerate: false,
                    })
                }).catch( error => {
                    let message = "Something went wrong, please check back later";
                    if (error.response.data.detail.includes("User create visits exceeded")){
                        message = "You’ve reached the maximum number of daily visits for your subscription. Upgrade for more daily visits or wait for your daily limit to refresh."
                    }
                    this.context.addAlert(message, "danger");
                    this.setState({
                        loadingNote: false,
                    })
                })

                const updateProgressIntervalID = setInterval(() => {
                    if (this.state.loadingProgress < 99) {
                        this.updateProgress();
                    }
                }, 500);

                // Repeatedly request status of file
                const intervalID = setInterval( async () => {
                    try {
                        const response = await axiosInstance.get(`/api/v1/visit/${this.visitID}`)
                        if (response.data['generate_note'] !== null && response.data['transcription'] !== null) {
                            // Update state
                            this.setState({
                                loadingNote: false,
                                noteGenerated: response.data['generate_note']['generated_result']['value'],
                                transcriptGenerated: response.data['transcription']['result']['transcript'],
                                loadingProgress: 100,
                                canGenerate: false,
                            })

                            clearInterval(intervalID);
                            clearInterval(updateProgressIntervalID);
                        } 
                    }
                    catch(error) {
                        this.context.addAlert("Something unexpected happen. Please try again", "danger");
                        this.setState({
                            loadingNote: false,
                        })
                        clearInterval(intervalID);
                        clearInterval(updateProgressIntervalID);
                    }
                }, 4000)

            } catch {
                this.context.addAlert("Something unexpected happen. Please try again", "danger");
                this.setState({
                    loadingNote: false,
                })
            }
        }
    }
    nameAppointment = (name) => {
        this.setState({
            promptNameAppointment: false,
            noteName: name,
        })
        this.generateProcess();
    }

    cancelAppointment = () => {
        this.setState({
            promptNameAppointment: false,
        })
        this.generateProcess();
    }
    raiseTimeWarning = () => {
        this.context.addAlert("Recordings are limited to 2 hours. Your recording will automatically stop at 2 hours.", "danger");
    }

    recordingLimitReached = () => {
        console.log("limit reached");
        this.limitReached = true;
        if (this.state.capturing) {
            this.stopMicrophone();
        }
        this.context.addAlert("You've hit the recording limit of 2.5 hours. Please hit 'complete' to save the note for your visit.", "danger");
    }

    handleEmailVisitDetails = async () => {
        await axiosInstance.post(`api/v1/visit/${this.visitID}/send_email`).then(response => {
            this.context.addAlert("Visit details sent to email", "success");
        }).catch(error => {
            this.context.addAlert("There was a problem sending visit details to email", "danger");
        })
    }

    render() {
        return (
            <Box>
                <NameAppointmentPopup open={this.state.promptNameAppointment} closePopup={this.cancelAppointment} handleName={this.nameAppointment}/>
                <NavBlocker dirty={this.state.capturing || (this.state.loadingProgress < 10 && this.state.loadingNote) || this.state.canGenerate || this.state.transcriptEditable || this.state.noteEditable || this.audioChunks.length > 0}/>
                <ConfirmEmailModal open={this.state.triggerSendEmail} closePopupExternal={() => this.setState({triggerSendEmail: false})} sendEmailExternal={this.handleEmailVisitDetails}/>
                <Sheet sx={{ display: { xs:"none", sm:"block"}, background: "transparent" }}>
                    <Box id="visit-window">
                        <Box className="row" id="space-between" style={{paddingLeft: '2px'}}>
                            <Box id="align-together">
                                <h2 ref={this.nameRef} contentEditable="true" suppressContentEditableWarning={true}>
                                    {this.state.noteName}
                                </h2>
                            </Box>
                            <Box id="ending-elements">
                                <p> Visit Date: {this.state.currentDate.toDateString()} </p>
                                <p> Started at: {this.state.visitTime}</p>
                            </Box>
                        </Box>

                        {this.state.noteGenerated && !this.state.loadingNote && <Box className="row" id="generate-options" style={{ marginLeft: '32px', marginRight: '18px', marginBottom: '10px' }}>
                            <Select placeholder="Note Type" defaultValue={this.state.noteType} disabled sx={{ height: '44px', width: "242px", alignSelf: "center" }}>
                                <Option value="athletic">Athletic Note</Option>
                                <Option value="chiropractic">Chiropractic Note</Option>
                                <Option value="consult_note">Consult Note</Option>
                                <Option value="consult_note_condensed"> Consult Note Condensed</Option>
                                <Option value="emergency_response"> Emergency Response</Option>
                                <Option value="pace_rehab_treatment_note"> PACE - Rehab Treatment Note </Option>
                                <Option value="patient_handout">Patient Handout</Option>
                                <Option value="patient_summary">Patient Summary</Option>
                                <Option value="physio">Physiotherapy Note</Option>
                                <Option value="progress_soap_note"> Progress SOAP Note</Option>
                                <Option value="radiology"> Radiology</Option>
                                <Option value="referral_letter">Referral Letter</Option>
                                <Option value="sports_medicine_consult_letter">Sports Medical Consult Letter</Option>
                                <Option value="sports_medicine">Sports Medical Note</Option>
                                <Option value="therapy_note"> Therapy Note</Option>
                                <Option value="therapy_cbt_dbt"> Therapy - CBT / DBT Note</Option>
                                <Option value="therapy_family_remediation"> Therapy - Family Mediation Note</Option>
                                <Option value="therapy_parenting_plan"> Therapy - Parenting Plan Note</Option>
                                <Option value="therapy_marriage_counselling"> Therapy - Partner Counselling Note</Option>

                            </Select>
                            <Button className="visit-button" id="copy-button" sx={{ color: "white", height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", '&:hover': { backgroundColor: 'var(--main-blue-hover)'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} startDecorator={<ContentCopyIcon/>} onClick={() => {
                                copyToClipboard(this.createNoteText());
                                this.context.addAlert("Copied!", "success");
                            }}>
                                Copy
                            </Button>
                            <Button id="transcript-button" sx={{ color: "white", height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", px: 2, marginRight: '15px', '&:hover': { backgroundColor: 'var(--main-blue-hover)'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} startDecorator={this.state.isDivVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>} onClick={this.toggleDivVisibility}>
                                {this.state.isDivVisible ? 'Hide Transcript' : 'Show Transcript'}
                            </Button>
                            <Button
                                    className="visit-button"
                                    id="email-button"
                                    variant="outlined" 
                                    color="neutral"
                                    sx={{ backgroundColor: 'white', height: '44px', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", marginRight: '15px'}}
                                    startDecorator={<IosShareIcon/>}
                                    onClick={() => this.setState({triggerSendEmail: true})}
                                >
                                    Email
                            </Button>
                        </Box>}


                        {this.state.noteGenerated && !this.state.loadingNote && <Divider sx={{'--Divider-thickness': '1px', alignSelf: 'center', width: '96%', margin: 'auto'}}/>}

                        {!this.state.noteGenerated && <Box id="generate-options">
                            <Box className="row">
                                <h3>Note Type: </h3>
                                <Select placeholder="Note Type" onChange={this.handleNoteType} value={this.state.noteType} disabled={this.state.loadingNote} sx={{ alignSelf: "center", marginLeft: "80px", width: "242px", height: '46px'}}>
                                    <Option value="athletic">Athletic Note</Option>
                                    <Option value="chiropractic">Chiropractic Note</Option>
                                    <Option value="consult_note">Consult Note</Option>
                                    <Option value="consult_note_condensed"> Consult Note Condensed</Option>
                                    <Option value="emergency_response"> Emergency Response</Option>
                                    <Option value="pace_rehab_treatment_note"> PACE - Rehab Treatment Note </Option>
                                    <Option value="patient_handout">Patient Handout</Option>
                                    <Option value="patient_summary">Patient Summary</Option>
                                    <Option value="physio">Physiotherapy Note</Option>
                                    <Option value="progress_soap_note"> Progress SOAP Note</Option>
                                     <Option value="radiology"> Radiology</Option>
                                    <Option value="referral_letter">Referral Letter</Option>
                                    <Option value="sports_medicine_consult_letter">Sports Medical Consult Letter</Option>
                                    <Option value="sports_medicine">Sports Medical Note</Option>
                                    <Option value="therapy_note"> Therapy Note</Option>
                                    <Option value="therapy_cbt_dbt"> Therapy - CBT / DBT Note</Option>
                                    <Option value="therapy_family_remediation"> Therapy - Family Mediation Note</Option>
                                    <Option value="therapy_parenting_plan"> Therapy - Parenting Plan Note</Option>
                                    <Option value="therapy_marriage_counselling"> Therapy - Partner Counselling Note</Option>
                                </Select>
                            </Box>
                            <Box className="row">
                                <h3>Capture Visit: </h3>
                                {!this.state.capturing && <Button onClick={this.toggleMicrophone} sx={{ width: "242px", textAlign: "start", padding: "11px", borderRadius: "5px", alignSelf: "center", paddingLeft: '20px', backgroundColor: "#2a3041", marginLeft: '57px'}} disabled={this.state.loadingNote}> START </Button>}
                                {this.state.capturing && <Button onClick={this.toggleMicrophone} sx={{ width: "242px", textAlign: "start", padding: "10px", borderRadius: "5px", alignSelf: "center", paddingLeft: '20px', backgroundColor: "white", color: 'black', border: '1px solid black', marginLeft: '57px', '&:hover': {color: 'white'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} disabled={this.state.loadingNote}> STOP/PAUSE </Button>}
                                <Button onClick={() => {
                                    this.temporaryScreenCheck = "desktop";
                                    if (/^New Visit \d+$/.test(this.state.noteName)) {
                                        this.setState({
                                            promptNameAppointment: true,
                                        })
                                    } else {
                                        this.generateProcess();
                                    }
                                }} sx={{ width: "242px", textAlign: "start", padding: "11px", borderRadius: "5px", alignSelf: "center", paddingLeft: '20px', ml: "20px" }} disabled={this.state.loadingNote || !this.state.timeAllowed}> COMPLETE </Button>
                                <Tooltip title={'Begin capturing your current visit by pressing START. Stop and Start as you like, and when you\'ve completed your visit, hit complete to create your structured note!'} sx={{ alignSelf:'center', marginLeft:'5px'}}>
                                    <HelpOutlineIcon/>
                                </Tooltip>
                            </Box>
                            <Stepper sx={{ width: '95%', paddingTop: '5px'}}>
                                <Step indicator={ <StepIndicator variant="outlined"> {this.state.noteType === '' ? '1.' : <CheckIcon color="#d3ffecff"/>} </StepIndicator>}>
                                    Choose Note Type
                                </Step>
                                <Step indicator={ <StepIndicator variant="outlined"> {this.state.canGenerate || this.state.capturing || this.state.loadingNote ? <CheckIcon color="#d3ffecff"/> : '2.'} </StepIndicator>}>
                                    Begin visit
                                </Step>
                                <Step indicator={ <StepIndicator variant="outlined"> {this.state.timeAllowed || this.state.loadingNote ? <CheckIcon color="#d3ffecff"/> : '3.'} </StepIndicator>}>
                                    Record at least 20 seconds
                                </Step>
                                <Step indicator={ <StepIndicator variant="outlined"> {(this.state.timeAllowed && !this.state.capturing) || this.state.loadingNote ? <CheckIcon color="#d3ffecff"/> : '4.'} </StepIndicator>}>
                                    Stop visit
                                </Step>
                                <Step indicator={ <StepIndicator variant="outlined"> {this.state.loadingNote ? <CheckIcon color="#d3ffecff"/> : '5.'} </StepIndicator>}>
                                    "COMPLETE" to create note
                                </Step>
                            </Stepper>
                        </Box>}
                        <Box style={{ display: 'flex', marginRight: '21px',
                            ...(this.state.noteGenerated && !this.state.loadingNote ? {
                                height: '90.5vh'
                            } : {
                                height: '55.5vh'
                            })
                        }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto', flex: this.state.isDivVisible && !this.state.loadingNote ? '0 0 55%' : '1', height: '90%'}}>
                                {this.state.noteGenerated && !this.state.loadingNote && (
                                    <Box className='note' style={{ display: 'flex', ...(this.state.noteGenerated && !this.state.loadingNote ? {
                                        marginLeft: '32px'
                                    } : {
                                        marginLeft: '0px'
                                    })}}>
                                        <Typography level='body-lg' alignSelf='center' sx={{marginRight: '10px'}}> Note: </Typography>
                                        <Button sx={{width: "fit-content", height: "fit-content", display: 'inline-flex', p: 1, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} className="visit-button" id="edit-note-button" startDecorator={this.state.noteEditable ? <ClearIcon/> : <EditIcon/>} onClick={() => {this.state.noteEditable ? this.handleCancelSaveNote() : this.handleEditNote()}}>
                                            {this.state.noteEditable ? "Cancel" : "Edit"}
                                        </Button>
                                        <Button variant="outlined" color="success" sx={{width: "fit-content", height: "fit-content", display: 'inline-flex', p: 1, border: this.state.noteEditable ? '1px solid #A1E8A1' : '1px solid #cdd7e1', color: this.state.noteEditable ? 'green' : 'black', '& .MuiSvgIcon-root': { color: this.state.noteEditable ? 'green' : '#9FA6AD', }, backgroundColor: 'white'}} startDecorator={<CheckIcon/>} onClick={() => this.handleNewSaveNote()} disabled={!this.state.noteEditable}>Save</Button>
                                        <Button 
                                            onClick={() => {
                                                this.state.downloadFormat === 'pdf' ? downloadText(this.createNoteText(), this.nameRef.current.innerText, this.state.currentDate.toISOString().slice(0, -1)) :
                                                    downloadTextoTxt(this.createNoteText(), this.nameRef.current.innerText, this.state.currentDate.toISOString().slice(0, -1))
                                            }}
                                            sx={{
                                                display: 'inline-flex',
                                                width: 'fit-content',
                                                height: 'fit-content',
                                                p: 1,
                                                border: '1px solid #cdd7e1',
                                                borderRadius: '6px',
                                                alignSelf: 'center',
                                                marginLeft: '15px',
                                                marginRight: '7px',
                                                backgroundColor: 'white',
                                                color: '#32383E'
                                            }}>
                                            <FileDownloadIcon/>
                                            Download
                                        </Button>
                                        <RadioGroup sx={{marginRight: "2vw", gap: '3px', alignSelf: 'center'}}>
                                            <Radio checked={this.state.downloadFormat === "pdf"} onChange={this.handleNoteDownloadFormat} value="pdf" variant="outlined" label=".pdf" size="sm"/>
                                            <Radio checked={this.state.downloadFormat === "txt"} onChange={this.handleNoteDownloadFormat} value="txt" variant="outlined" label=".txt" sx={{marginBlockStart: '0px'}} size="sm"/>
                                        </RadioGroup>
                                    </Box>
                                    )}

                                    {!this.state.noteGenerated && !this.state.loadingNote && (
                                        <Box className="transcript-box" style={{border: '2px solid var(--light-gray)', borderRadius: '10px'}}>
                                            <Box id="timer-style">
                                                <TimerComponent start={this.state.capturing} raiseWarning={this.raiseTimeWarning} limitReached={this.recordingLimitReached} allowGeneration={this.allowGeneration}/>
                                                {this.state.capturing && <Box className="recording-dot"></Box>}
                                            </Box>
                                            {this.state.audio && (
                                                <Box id="center-all-elements">
                                                    <h3>CAPTURING...</h3>
                                                    <Box id="waveform-bounds">
                                                        {this.state.audio && <AudioAnalyser audio={this.state.audio}/>}
                                                    </Box>
                                                </Box>
                                            )}
                                            <h4 id="permission">Make sure to obtain patient consent if you're using clinicassist in an appointment.</h4>
                                        </Box>
                                    )}
                                    {!this.state.noteGenerated && this.state.loadingNote && (
                                        <Box className="transcript-box" style={{border: '2px solid var(--light-gray)', borderRadius: '10px'}}>
                                        <Box id="center-all-elements">
                                                    <h3> Processing...</h3>
                                                    <Box sx={{ width: '50%' }}>
                                                        <LinearProgress determinate size="sm" value={this.state.loadingProgress}/>
                                                    </Box>
                                                    <h4>Make sure to obtain patient consent if you're using clinicassist in an appointment.</h4>
                                            </Box>
                                    </Box>
                                    )}
                                    {this.temporaryScreenCheck === "desktop" && this.state.noteGenerated && !this.state.loadingNote && ((checkExistingTitles(this.state.noteGenerated)) ? 
                                    <Box className="transcript-box" id="note-generated">
                                        {nonJsonToJson(this.state.noteGenerated, this.setTitles, this.setRefs, this.specialRefs, this.state.noteEditable, this.setFormattableParts)}
                                    </Box> : 
                                    <Box className="transcript-box" id="note-generated">
                                        <Typography ref={this.noteRef} contentEditable={this.state.noteEditable} suppressContentEditableWarning={true} padding="15px"> {this.state.noteGenerated} </Typography>
                                    </Box>)}
                                </Box>

                            {this.state.isDivVisible && this.state.noteGenerated && !this.state.loadingNote && <Divider orientation="vertical" flexItem sx={{'--Divider-thickness': '1px', height: '90%'}}/>}        
                            {this.state.transcriptGenerated && this.state.isDivVisible && (
                            <Box id="fit-content" style={{ flex: '0 0 43%', height: '90%' }}>
                                <Box className="row" style={{marginTop: '10px', marginBottom: '8px'}}>
                                <Typography level='body-lg' sx={{alignSelf: "center", marginRight: '10px'}}> Transcript: </Typography>
                                    <Button variant="outlined" color="neutral" sx={{width: "fit-content", height: "fit-content", alignSelf: 'center', display: 'inline-flex', p: 1, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} className="visit-button" id="edit-note-button" startDecorator={this.state.transcriptEditable ? <ClearIcon/> : <EditIcon/>} onClick={() => {this.state.transcriptEditable ? this.handleCancelSaveTranscript() : this.handleEditTranscript()}}>
                                        {this.state.transcriptEditable ? "Cancel" : "Edit"}
                                    </Button>
                                    <Button variant="outlined" color="success" sx={{width: "fit-content", height: "fit-content", display: 'inline-flex', p: 1, border: this.state.transcriptEditable ? '1px solid #A1E8A1' : '1px solid #cdd7e1', color: this.state.transcriptEditable ? 'green' : 'black', '& .MuiSvgIcon-root': { color: this.state.transcriptEditable ? 'green' : '#9FA6AD', }, backgroundColor: 'white'}} startDecorator={<CheckIcon/>} onClick={() => this.handleRegenerateNote()} disabled={!this.state.transcriptEditable}>Save</Button>
                                    <Tooltip title={'Feel free to review the recorded transcript and recreate a new structured note based off your new changes!'} sx={{ alignSelf:'center', marginLeft:'5px'}}>
                                        <HelpOutlineIcon/>
                                    </Tooltip>
                                </Box>
                                <Box sx={{
                                    height: {
                                        sm: "51vh", 
                                        md: "63vh", 
                                        lg: "73vh"
                                    },
                                    overflowY: "auto", 
                                    overflowX: 'scroll'}}>
                                    <Typography level="body-sm" sx={{ whiteSpace: 'pre-line' }} ref={this.transcriptRef} contentEditable={this.state.transcriptEditable} suppressContentEditableWarning={true}> {this.state.transcriptGenerated} </Typography>
                                </Box>
                            </Box>)}
                        </Box>
                    </Box>
                </Sheet>
                <Sheet sx={{ display: { xs:"block", sm:"none"}, background: "transparent" }}>
                    <Box id="visit-window-smUp">
                        <Input value={this.state.noteName} variant="plain" sx={{ fontWeight: '600', fontSize: '1.7rem', marginLeft: '10px'}} onChange={(event) => {
                                    this.setState({ noteName: event.target.value })
                        }}/>
                        {!this.state.noteGenerated && !this.state.loadingNote && (
                                <Box className="transcript-box-smUp">
                                    <Box id="timer-style">
                                        <TimerComponent start={this.state.capturing} raiseWarning={this.raiseTimeWarning} limitReached={this.recordingLimitReached} allowGeneration={this.allowGeneration}/>
                                    </Box>
                                    {this.state.audio && (
                                        <Box id="center-all-elements">
                                            <h3> CAPTURING...</h3>
                                            <h3> Please try to speak clearly, and close to the capture device for the best results.</h3>
                                            <Box id="waveform-bounds">
                                                {this.state.audio && <AudioAnalyser audio={this.state.audio}/>}
                                            </Box>
                                            <h4>Make sure to obtain patient consent if you're using clinicassist in an appointment.</h4>
                                        </Box>
                                    )}
                                    {!this.state.audio && <h4 id="permission">Make sure to obtain patient consent if you're using clinicassist in an appointment.</h4>}
                                </Box>
                            )}
                        {!this.state.noteGenerated && this.state.loadingNote && (
                            <Box className="transcript-box-smUp">
                            <Box id="center-all-elements">
                                        <h3> Processing...</h3>
                                        <Box sx={{ width: '50%' }}>
                                            <LinearProgress determinate value={this.state.loadingProgress}/>
                                        </Box>
                                        <h4>Make sure to obtain patient consent if you're using clinicassist in an appointment.</h4>
                                </Box>
                            </Box>
                        )}
                        {this.state.noteGenerated && <Box>
                            <h3 id="summary-title"> SUMMARY: </h3>
                            <Box id="generate-options-container">
                                <Select placeholder="Note Type" defaultValue={this.state.noteType} disabled sx={{ alignSelf: "center", marginLeft: "20px", width: "fit-content"}}>
                                    <Option value="athletic">Athletic Note</Option>
                                    <Option value="chiropractic">Chiropractic Note</Option>
                                    <Option value="consult_note">Consult Note</Option>
                                    <Option value="consult_note_condensed"> Consult Note Condensed</Option>
                                    <Option value="emergency_response"> Emergency Response</Option>
                                    <Option value="pace_rehab_treatment_note"> PACE - Rehab Treatment Note </Option>
                                    <Option value="patient_handout">Patient Handout</Option>
                                    <Option value="patient_summary">Patient Summary</Option>
                                    <Option value="physio">Physiotherapy Note</Option>
                                    <Option value="progress_soap_note"> Progress SOAP Note</Option>
                                    <Option value="radiology"> Radiology</Option>
                                    <Option value="referral_letter">Referral Letter</Option>
                                    <Option value="sports_medicine_consult_letter">Sports Medical Consult Letter</Option>
                                    <Option value="sports_medicine">Sports Medical Note</Option>
                                    <Option value="therapy_note"> Therapy Note</Option>
                                    <Option value="therapy_cbt_dbt"> Therapy - CBT / DBT Note</Option>
                                    <Option value="therapy_family_remediation"> Therapy - Family Mediation Note</Option>
                                    <Option value="therapy_parenting_plan"> Therapy - Parenting Plan Note</Option>
                                    <Option value="therapy_marriage_counselling"> Therapy - Partner Counselling Note</Option>
                                </Select>
                                <Button className="visit-button" id="complete-button-smUp" onClick={() => {
                                    copyToClipboard(this.createNoteText());
                                    this.context.addAlert("Copied!", "success");
                                }}>COPY</Button>
                                <Button
                                    className="visit-button" sx={{ marginLeft: "15px" }}
                                    onClick={() => this.setState({triggerSendEmail: true})}
                                >
                                    SEND TO EMAIL
                                </Button>
                            </Box>
                        </Box>
                        }

                        {this.temporaryScreenCheck === "mobile" && this.state.noteGenerated && !this.state.loadingNote && ((checkExistingTitles(this.state.noteGenerated)) ? 
                            <Box className="transcript-box" id="note-generated" sx={{
                                height: {
                                    sm: "51vh", 
                                    md: "63vh", 
                                    lg: "73vh"
                                }}}>
                                {nonJsonToJson(this.state.noteGenerated, this.setTitles, this.setRefs, this.specialRefs, this.state.noteEditable, this.setFormattableParts)}
                            </Box> : 
                            <Box className="transcript-box" id="note-generated" sx={{
                                height: {
                                    sm: "51vh", 
                                    md: "63vh", 
                                    lg: "73vh"
                                }}}>
                                <Box id="scrollable">
                                    <Typography ref={this.noteRef} contentEditable={this.state.noteEditable} suppressContentEditableWarning={true} padding="15px"> {this.state.noteGenerated} </Typography>
                                </Box>
                            </Box>)}

                        {!this.state.noteGenerated && <Box id="generate-options-smUp">
                            <Box className="row">
                                <h3>Note Type: </h3>
                                <Select placeholder="Note Type" onChange={this.handleNoteType} value={this.state.noteType} disabled={this.state.loadingNote} sx={{ alignSelf: "center", marginLeft: "20px", width: "fit-content", height: '46px'}}>
                                    <Option value="athletic">Athletic Note</Option>
                                    <Option value="chiropractic">Chiropractic Note</Option>
                                    <Option value="consult_note">Consult Note</Option>
                                    <Option value="consult_note_condensed"> Consult Note Condensed</Option>
                                    <Option value="emergency_response"> Emergency Response</Option>
                                    <Option value="pace_rehab_treatment_note"> PACE - Rehab Treatment Note </Option>
                                    <Option value="patient_handout">Patient Handout</Option>
                                    <Option value="patient_summary">Patient Summary</Option>
                                    <Option value="physio">Physiotherapy Note</Option>
                                    <Option value="progress_soap_note"> Progress SOAP Note</Option>
                                    <Option value="radiology"> Radiology</Option>
                                    <Option value="referral_letter">Referral Letter</Option>
                                    <Option value="sports_medicine_consult_letter">Sports Medical Consult Letter</Option>
                                    <Option value="sports_medicine">Sports Medical Note</Option>
                                    <Option value="therapy_note"> Therapy Note</Option>
                                    <Option value="therapy_cbt_dbt"> Therapy - CBT / DBT Note</Option>
                                    <Option value="therapy_family_remediation"> Therapy - Family Mediation Note</Option>
                                    <Option value="therapy_parenting_plan"> Therapy - Parenting Plan Note</Option>
                                    <Option value="therapy_marriage_counselling"> Therapy - Partner Counselling Note</Option>
                                </Select>
                            </Box>
                            <Box className="row">
                                <h3>Capture: </h3> 
                                <Box id="buttons-smUp">
                                    {!this.state.capturing && <Button onClick={this.toggleMicrophone} className="visit-button" id="start-button-smUp" disabled={this.state.loadingNote}> START </Button>}
                                    {this.state.capturing && <Button onClick={this.toggleMicrophone} className="visit-button" id="stop-button-smUp" disabled={this.state.loadingNote}> STOP </Button>}
                                    {this.state.capturing && <Box className="recording-dot"></Box>}
                                    {!this.state.capturing && <Box className="placeholder-smUp"></Box>}
                                    <Button onClick={() => {
                                        this.temporaryScreenCheck = "mobile";
                                        if (/^New Visit \d+$/.test(this.state.noteName)) {
                                            this.setState({
                                                promptNameAppointment: true,
                                            })
                                        } else {
                                            this.generateProcess();
                                        }
                                    }
                                    } className="visit-button" id="complete-button-smUp" disabled={this.state.loadingNote}> COMPLETE </Button>
                                </Box>
                            </Box>
                        </Box>}
                    </Box>
                </Sheet>
            </Box>
        )
    }
}

export default NewVisitView;