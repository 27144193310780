import React, { useState, useEffect, useContext } from "react"
import "../styling/CurrentVisit.css";
import { copyToClipboard } from "./CopyToCliboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip, Typography } from "@mui/joy";
import { AlertContext } from "../components/AlertFlag";

export const formattable = ['consult_note', 'progress_soap_note', "referral_letter", "patient_handout", "patient_summary", "physio", "athletic", "chiropractic", "sports_medicine", "sports_medicine_consult_letter", "consult_note_condensed", "consult_note_multiple_problems"];

export function formatNoteName(string) {
    switch(string) {
        case ("physio"): {
            return "Physiotherapy Note"
        } case ("athletic"): {
            return "Athletic Note"
        } case ("chiropractic"): {
            return "Chiropractic Note"
        } case ("sports_medicine"): {
            return "Sports Medical Note"
        } case ("sports_medicine_consult_letter"): {
            return "Sports Medical Consult Letter"
        } case ("radiology"): {
            return "Radiology"
        } case ("therapy_cbt_dbt"): {
            return "Therapy - CBT / DBT Note"
        } case ("therapy_family_remediation"): {
            return "Therapy - Family Mediation Note"
        } case ("therapy_marriage_counselling"): {
            return "Therapy - Partner Counselling Note"
        } case ("therapy_parenting_plan"): {
            return "Therapy - Parenting Plan Note"
        } case ("emergency_response"): {
            return "Emergency Response"
        } case ("therapy_note"): {
            return "Therapy Note"

        } default: {
            return capitalizeWords(string, "_")
        }
    }
}

function capitalizeWords(sentence, factor) {
    const words = sentence.split(factor);
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
  }

export function checkExistingTitles(string) {
    const regex = /\*\*([^*]+)\*\*/g;
    return regex.test(string);
}

function NoteDisplayCard(props) {
    const { addAlert } = useContext(AlertContext);

    const [titles, setTitles] = useState([]);
    const [parts, setParts] = useState([]);
    const [editable, setEditable] = useState(false);
    

    useEffect( () => {
        setTitles(props.noteTitles);
        setParts(props.noteText);
        setEditable(props.editable);
    }, [props.editable, props.noteTitles])


    return <>
        {(titles.map((title, index) => {
            return <div key={`${title} ${index}`}>
             <div className="row" style={{marginBottom: '4px'}}>
                <Typography level="body-lg" sx={{alignSelf: "center", m: 0, color: 'var(--main-blue)', fontWeight: 'bold', fontSize: '1.225rem'}}>
                    {capitalizeWords(title.toLowerCase(), ' ')}
                </Typography>
                {!editable && <Tooltip title="Copy section" size="sm">
                    <IconButton onClick={() => {
                        addAlert("Section Copied!", "success")
                        copyToClipboard(parts[index])
                        }} sx={{ width: "fit-content", alignSelf: "center"}}>
                        <ContentCopyIcon fontSize="lg"/>
                    </IconButton>
                </Tooltip>}
            </div>
            <Typography contentEditable={editable} ref={props.assignRefs[index]} suppressContentEditableWarning={true} level="body-sm" sx={{color: '#2a3041'}}>
                {`${parts[index]} \n\n`}
            </Typography>
            </div>
        })
        )}
    </>
}

export function nonJsonToJson( soapString, setTitles, setRefs, assignRefs, editable, setParts ) {
    const text=`Ensure start ${soapString}`;
    let match;
    const titles = [];
    const regex = /\*\*([^*]+)\*\*/g;
    let parts = [];

    while ((match = regex.exec(text)) !== null) {
        titles.push(match[1]);
    }
    setTitles(titles);

    let lastIndex = 0;
    let refsIndex = 0;
    for (let i = 0; i < titles.length; i++) {
        const title = titles[i];
        const index = text.indexOf(`**${title}**`, lastIndex) - 1;
        if (index !== -1) {
            parts.push(text.substring(lastIndex, index).trim());
            setRefs(refsIndex);
            refsIndex += 1;
            lastIndex = index + title.length + 6; 
        }
    }

    // Add the last part of the text
    if (lastIndex < text.length) {
        parts.push(text.substring(lastIndex).trim());
        setRefs(refsIndex);
    }

    // Remove start
    parts = parts.slice(1);
    setParts(parts);

    return <NoteDisplayCard noteText={parts} noteTitles={titles} assignRefs={assignRefs} editable={editable}/>
}


